import { Typography, Card, styled, IconButton, MenuItem, Menu, Chip } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Link from 'next/link';
import Image from 'next/legacy/image';
import dynamic from 'next/dynamic';
import { useState } from 'react';
import dayjs from 'dayjs';
import { Course } from ':src/types';
import { CourseTypeEnum } from ':src/lookup';
import { COURSE_DATE_FORMAT } from ':src/constants';
import LineClamp from '../LineClamp';

const UnenrollModal = dynamic(() => import(':src/components/UnenrollModal'));

const CourseName = styled(LineClamp)``;

const CardAnchor = styled(Card)<{ component: string }>({
  display: 'block',

  '&:hover': {
    boxShadow: '0px 5px 8px 2px rgba(0, 0, 0, 0.1)',

    [`${CourseName}`]: {
      textDecoration: 'underline',
    },

    img: {
      transform: 'scale(1.08)',
    },
  },

  '&:focus': {
    outline: '2px solid green',

    img: {
      transform: 'scale(1.08)',
    },
  },
});

export const ImageWrapper = styled('div', { shouldForwardProp: (prop) => prop !== 'isVertical' })<{
  isVertical: boolean;
}>(({ theme, isVertical }) => ({
  backgroundColor: theme.palette.grey[300],
  position: 'relative',
  paddingBottom: '56.25%',

  img: {
    transition: 'transform 300ms',
  },

  ...(!isVertical && {
    [theme.breakpoints.up('sm')]: {
      flexShrink: 0,
      padding: 0,
      height: 175,
      width: 311,
    },
  }),
}));

export const CardContent = styled('div', { shouldForwardProp: (prop) => prop !== 'isVertical' })<{
  isVertical: boolean;
}>(({ theme, isVertical }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  color: theme.palette.text.primary,

  ...(isVertical
    ? {}
    : {
        [theme.breakpoints.up('sm')]: {
          flexDirection: 'row',
        },
      }),
}));

export const CourseContent = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: 160,

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(3),
    height: 175,
  },
}));

const CourseMetadata = styled('div', { shouldForwardProp: (prop) => prop !== 'isVertical' })<{
  isVertical: boolean;
}>(({ theme, isVertical }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  ...(isVertical
    ? {}
    : {
        [theme.breakpoints.up('sm')]: {
          justifyContent: 'flex-start',
          gap: theme.spacing(2),
        },
      }),
}));

const CreditChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.gold[50],
  color: theme.palette.gold[600],
  fontWeight: 600,
}));

const MenuButton = styled(IconButton)({
  position: 'absolute',
  top: 8,
  right: 8,
});

export type PartialCourse = Omit<Course, 'description' | 'duration' | 'panelists'>;

export type CourseCardProps = {
  course: PartialCourse;
  isVertical?: boolean;
};

export default function CourseCard({
  course: {
    id,
    slug,
    name,
    summary,
    courseTypeId,
    viewerEnrollment,
    thumbnailPath,
    scheduledFor: scheduledForISO,
    creditHours,
  },
  isVertical = false,
}: CourseCardProps) {
  const scheduledFor = scheduledForISO ? dayjs(scheduledForISO) : null;
  const isEnrolled = !!viewerEnrollment;
  const [isUnenrollModalOpen, setIsUnenrollModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(null);
  };

  const isOnDemand = courseTypeId === CourseTypeEnum.ON_DEMAND;
  const isInProgress = false;

  return (
    <>
      <Link href={`/courses/${slug}`} passHref legacyBehavior>
        <CardAnchor component="a">
          <CardContent isVertical={isVertical}>
            {isEnrolled && (
              <>
                <MenuButton onClick={handleMenuClick}>
                  <MoreVertIcon />
                </MenuButton>
                <Menu
                  anchorEl={anchorEl}
                  open={isMenuOpen}
                  onClose={handleMenuClose}
                  onClick={handleMenuClose}
                >
                  <MenuItem onClick={() => setIsUnenrollModalOpen(true)}>Unenroll</MenuItem>
                </Menu>
              </>
            )}
            <ImageWrapper isVertical={isVertical}>
              {thumbnailPath && (
                <Image
                  src={thumbnailPath}
                  layout="fill"
                  objectFit="cover"
                  alt={`Image for ${name}`}
                  sizes="350px"
                />
              )}
            </ImageWrapper>

            <CourseContent>
              <div>
                <CourseName variant="h5" mb={1} maxLines={2}>
                  {name}
                </CourseName>
                <LineClamp maxLines={2} variant="body2">
                  {summary}
                </LineClamp>
              </div>

              <CourseMetadata isVertical={isVertical}>
                {isOnDemand ? (
                  <>
                    {isInProgress ? (
                      <Typography variant="body2" fontWeight={600} color="green.500">
                        In progress
                      </Typography>
                    ) : (
                      <Typography variant="body2" fontWeight={600}>
                        On demand
                      </Typography>
                    )}
                  </>
                ) : (
                  <Typography variant="body2" fontWeight={600}>
                    {scheduledFor!.format(COURSE_DATE_FORMAT)}
                  </Typography>
                )}
                <CreditChip size="small" label={`${creditHours} credits`} />
              </CourseMetadata>
            </CourseContent>
          </CardContent>
        </CardAnchor>
      </Link>
      {isUnenrollModalOpen && (
        <UnenrollModal
          id={id}
          slug={slug}
          open={isUnenrollModalOpen}
          onClose={() => setIsUnenrollModalOpen(false)}
        />
      )}
    </>
  );
}
